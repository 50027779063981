/**
 * Created by zhaoyue on 16/11/28.
 */

export const ID = '@auth-manage';

export default {
  ID: ID,
  GET_POWERS_DETAIL: `${ID}/GET_POWERS_DETAIL`,
  GET_POWERS_STAFFS: `${ID}/GET_POWERS_STAFFS`,
  GET_BASE_STAFFS: `${ID}/GET_BASE_STAFFS`,
  GET_CORPORATION_PACKAGE: `${ID}/GET_CORPORATION_PACKAGE`,
  PUT_POWERS_STAFFS: `${ID}/PUT_POWERS_STAFFS`,
  PUT_POWERS_AUTO_ADD: `${ID}/PUT_POWERS_AUTO_ADD`,
  POST_VERIFY_CODES: `${ID}/POST_VERIFY_CODES`,
  POST_RENEWAL_SERVICE: `${ID}/POST_RENEWAL_SERVICE`,
  GET_UNOPEN_STAFFS: `${ID}/GET_UNOPEN_STAFFS`,
  GET_OPENED_STAFFS: `${ID}/GET_OPENED_STAFFS`,
  GET_ORDER_LIST: `${ID}/GET_ORDER_LIST`,
  SAVE_OPEN_STAFFS: `${ID}/SAVE_OPEN_STAFFS`,
};
