// @i18n-ignore-all
import * as actions from './auth-manage-action';
import { app as api } from '@ekuaibao/whispered';

export default [
  {
    id: '@auth-manage',
    reducer: () => import('./auth-manage-reducer'),
    path: '/auth-manage',
    ref: '/',
    onload: () => import('./auth-manage-view'),
    dependencies: ['@auth-check'],
    'get:base:staff': () => api.dispatch(actions.getBaseStaff({ powerCode: '110101' })),
    'save:authorize:staff': (params) => api.dispatch(actions.saveOpenStaffs(params)),
  },
  {
    point: '@@menus',
    onload: () => [
      {
        id: 'auth-manage',
        pId: 'enterprise-manage',
        permissions: ['SYS_ADMIN'],
        plantFormUnSupport:
          window.__PLANTFORM__ === 'KD_CLOUD' || window.__PLANTFORM__ === 'HUAWEI',
        weight: 1,
        label: '功能授权',
        href: '/auth-manage',
        icon: 'menu-loan',
      },
    ],
  },
  {
    point: '@@layers',
    prefix: '@auth-manage',
    onload: () => import('./layer'),
  },
];
