/**
 * Created by zhaoyue on 16/11/28.
 */
import key from './key';

import { Resource } from '@ekuaibao/fetch';
import { QuerySelect } from 'ekbc-query-builder';
const powers = new Resource('/api/charge/powers');
export const staffs = new Resource('/api/v1/organization/staffs');
const assistPlatform = new Resource('/api/assistPlatform');
const charge = new Resource('/api/charge');

//获取功能详情 /api/charge/powers/detail/${powerCode}
export function getPowersDetail(powerCode, done) {
  return {
    type: key.GET_POWERS_DETAIL,
    payload: powers.GET('/detail/$powerCode', { powerCode }),
    done,
  };
}

//获取某企业的某功能已授权的员工id集合 /api/charge/powers/staff
export function getPowersStaff(params, done) {
  return {
    type: key.GET_POWERS_STAFFS,
    payload: powers.GET('/staff', params),
    done,
  };
}

export function getBaseStaff(params, done) {
  return {
    type: key.GET_BASE_STAFFS,
    payload: powers.GET('/staff', params),
    done,
  };
}

export function getCorporationPackage() {
  return {
    type: key.GET_BASE_STAFFS,
    payload: assistPlatform.GET('/getCorporationPackage'),
  };
}

//设置自动激活 /api/charge/powers/autoAdd/${powerCode}
export function putPowersAutoAdd(params, done) {
  const autoAdd = params.autoAdd;
  return {
    type: key.PUT_POWERS_AUTO_ADD,
    payload: powers.PUT(`/autoAdd/$powerCode/${autoAdd}`, { ...params }),
    done,
  };
}

//获取功能验证码 /api/innerapi/v1/verifyCodes
// 获取待授权员工列表
export function getUnopenStaffs(params, done) {
  return {
    type: key.GET_UNOPEN_STAFFS,
    payload: staffs.POST('/toBeAuthorized', params),
    done,
  };
}

// 获取已授权员工列表
export function getOpenedStaffs(params, done) {
  return {
    type: key.GET_OPENED_STAFFS,
    payload: staffs.GET('/authorized/byPowerCode', params),
    done,
  };
}

// 保存授权已开通员工
export function saveOpenStaffs(params, done) {
  return {
    type: key.SAVE_OPEN_STAFFS,
    payload: powers.POST('/authStaff', params),
    done,
  };
}
// 获取订单列表
export function getOrderList(start, count) {
  const query = new QuerySelect().limit(start, count);
  return {
    type: key.GET_ORDER_LIST,
    payload: charge.POST('/saleOrders', query.value()),
  };
}
